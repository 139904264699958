import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Dashboard from '../components/Dashboard'

const SignUpPage = () => {
    return (
        <>
        <ScrollToTop/>
        <Dashboard />
        </>
    )
}

export default SignUpPage
