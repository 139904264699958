import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import UpdateProfile from '../components/Updateprofile'

const UpdateProfilePage = () => {
    return (
        <>
        <ScrollToTop/>
        <UpdateProfile />
        </>
    )
}

export default UpdateProfilePage
