import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Signin from '../components/Signin'

const SignInPage = () => {
    return (
        <>
        <ScrollToTop/>
        <Signin />
        </>
    )
}

export default SignInPage
