export const homeObjOne ={
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Keep your home safe',
    headline: 'Real-time, low cost & easy to implement security.',
    description: 'GuardMyPi deploys facial recognition to keep you and your family safe, in a security tool you can understand, customise and trust.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/svg-1.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true, 
};

export const homeObjTwo ={
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pet detection',
    headline: 'Have a pet? No problem.',
    description: 'GuarMyPi avoids pesky false alarms via a pet-detection algorithm using a fast machine learning implementation.',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/svg-2.svg').default,
    alt: 'Dog',
    dark: true,
    primary: true,
    darkText: true, 
};

export const homeObjThree ={
    id: 'signup',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Peace of Mind',
    headline: 'Always keeping an eye when you\'re not there.',
    description: 'If an intrusion occurs, GuardMyPi will notify you and you\'ll be able to access live feed of your home on your GuardMyPi web-app',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/svg-4.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false, 
};