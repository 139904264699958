import React, { useRef, useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { db } from "../../firebase"

import {
Form,
FormWrap,
FormLabel,
FormInput,
FormH1,
FormButton,
StreamingLink,
FormH2,
Container,
Box,
Text,
Icon,
Alert,
FormContent} from './SigninElements'

import {Button, ButtonRouter} from '../ButtonElement';

export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout, getuserprofile} = useAuth()
  const history = useHistory()

  const getUserDocument = async uid => {
    if (!uid) return null;
    try {
      const userDocument = await db.doc(`users/${uid}/`).get();
      return {
        ...userDocument.data()
      };
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/signin")
    } catch {
      setError("Failed to log out")
    }
  }

    return (
        <>
        <Container>
            <FormWrap>
                <Icon to ="/">GuardMyPi</Icon>
                <FormContent>
                <Box>
                    {currentUser && <FormH1>Profile</FormH1>}
                    {error && <Alert>{error}</Alert>}
                    {currentUser && <FormH2>Email: {currentUser.email}</FormH2>}

                    {currentUser.email==='magnusbc98@gmail.com' && <StreamingLink href='https://taliped-elephant-9748.dataplicity.io/stream_simple.html' target='_blank'>Streaming</StreamingLink>}

                    {currentUser && <FormButton><Link to ="/update-profile" style={{textDecoration: 'none', color: '#ffff'}}>Update Profile</Link></FormButton>}
                    {currentUser && <FormButton onClick={handleLogout}><Link to ="/signin" style={{textDecoration: 'none', color: '#ffff'}}>Log Out</Link></FormButton>}
                    {currentUser==null && <FormButton onClick={handleLogout}><Link to ="/signin" style={{textDecoration: 'none', color: '#ffff'}}>Sign In</Link></FormButton>}
                  </Box>
            </FormContent>
            </FormWrap>
        
        </Container> 
    </>
    )
        
}
